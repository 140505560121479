/* User View, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="user-view" class="entity-view">
      <v-card-title>{{entity.FirstName}} {{entity.LastName}} - {{entity.Email}}</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'user-edit', params: { id: entity.Id } }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <span>Edit</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn
          color="secondary"
          class="me-3"
          @click="Back"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiChevronLeft }}
          </v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-simple-table>
        <thead>
          <tr>
            <th colspan="2">USER DETAILS</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Email</td>
            <td>{{ entity.Email }}</td>
          </tr>

          <tr>
            <td>First Name</td>
            <td>{{ entity.FirstName }}</td>
          </tr>

          <tr>
            <td>Last Name</td>
            <td>{{ entity.LastName }}</td>
          </tr>

          <tr>
            <td>LastLogin</td>
            <td>{{ Display.DateTime(entity.LastLogin) }}</td>
          </tr>

          <tr>
            <td>Role</td>
            <td>{{ entity.RoleDisplay }}</td>
          </tr>

          <tr>
            <td>Enabled</td>
            <td><v-icon size="18" :color="booleanIcons(entity.Enabled).variant">{{ booleanIcons(entity.Enabled).icon }}</v-icon></td>
          </tr>
        </tbody>
      </v-simple-table>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'

import {
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClose,
  mdiChevronLeft
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.User);
    const loading = ref(false);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchUser', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'user-list'});
        })
    }

    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);

    const Back = () => {
      router.go(-1);
    };

    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }


    return {
      Back,
      loading,
      entity,
      Display,
      booleanIcons,
      icons: {
        mdiPencilOutline,
        mdiClose,
        mdiChevronLeft
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

